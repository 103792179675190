.signup-container{
  width: 100%;
  /* margin: 10px 0px; */
}
.signup-container form{
  width: calc(100% - 40px);
  max-width: 450px;
}

.signup-google{
  background-color: #4286f6;
}
.signup-facebook{
  background-color: #3e5b99;
}
.signup-apple{
  background-color: black;
}
.signup-container > button{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: calc(100% - 40px);
  max-width: 450px;
  font-size: 1.2em;
  margin-top: 10px;
}

.signup-container button:active{
  background-color: grey;
}

.signup-container p.msg{
  color: grey;
}
.signup-container p.msg > a{
  color: var(--MAIN_COLOR);
  font-weight: bold;
  letter-spacing: 1.2px;
  text-decoration: none;
}