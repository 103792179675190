#adobe-dc-view{
    position: relative;
}

.howtouse-btn{
    align-self: center;
}
.howtouse-btn > a{
    color: white;
    text-decoration: none;
}