.website-container{
    width: 100%;
    max-width: 100vw;
    height: auto;
    margin-top: 20px;
    /* overflow: hidden; */
    /* background-color: red; */
}

#slime_svg {
    width:50vw;
    max-width: 500px;
    height:50vw;
    max-height: 500px;
}

#slime_svg path {
    cursor: pointer;
}
#slime_svg text{
    color: white;
    fill: white;
}

.svgContainer{
    height: auto;
}

.website-container > span{
    padding: 0px 10px;
    text-align: center;
}

.website-container > .tech {
    max-width: var(--MAX_WIDTH);
    /* background-color: red; */

}
.website-container > .tech > .widget{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* width: var(--MAX_WIDTH); */
    margin-top: 10px;
    /* background-color: red; */
}
.website-container > .tech > .widget span{
    min-width: 100px;
    text-align: end;
}
.website-container > .tech > .widget span > p{
    font-size: 0.8em;
}
.website-container > .tech > .widget img{
    max-height: 100px;
    width: 150px;
    object-fit: contain;
    /* background-color: red; */
    margin-left: 20px;
}


@media (max-width: 768px) { /* mobile */
    #slime_svg {
        width:80vw;
        height:80vw;
    }
  
  }