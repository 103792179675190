.terms-con{
    width: 100%;
    height: auto;
    margin: 20px 0px;
    font-size: 0.8em;
    /* background-color: red; */
}
.terms-con > .container{
    width: 100%;
    max-width: var(--MAX_WIDTH);
}




.terms-con h1,
.terms-con h2,
.terms-con h3,
.terms-con h4,
.terms-con h5,
.terms-con h6{
    padding: 0px;
    margin: 0px 20px;
    /* background-color: red; */
}

.terms-con p{
    width: calc(100% - 40px);
    padding: 10px 20px;
}
.terms-con b{

}
.terms-con h1{
    width: 100%;
    text-align: center;
    align-self: center;
    
}