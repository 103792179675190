.mobile-date-btn-con{
    width: calc(100% - 20px);
    height: auto;
    padding: 0px 10px;
    /* background-color: red; */
}
.mobile-date-btn-con > .widget{
    /* background-color: red; */
    width: calc( ( 100% / 7 ) - 5px );
    border-bottom: thin solid rgba(0, 0, 0, 0.2);
}
.mobile-date-btn-con > .widget.active{
    background-color: var(--MAIN_COLOR);
    color: white;
    border-radius: 5px 5px 0px 0px;
    border-bottom: none;
}
.mobile-date-btn-con > .widget.disabled{
    background-color: var(--BG_WHITE_COLOR);
    color: grey;
    border-radius: 5px 5px 0px 0px;
    border-bottom: none;
    cursor: not-allowed;
}
.mobile-date-widget-card{
    width: calc(100% - 20px);
    height: auto;
    padding: 10px;
    margin-top: 10px;
}
.mobile-date-widget-card > .header{
    width: calc(100% - 20px);
    height: auto;
    padding: 3px 10px;
    color: white;
}
.mobile-date-widget-card > .header span{
    /* background-color: red; */
    width: calc(100% - 60px);
}
.mobile-date-widget-card > .header span::first-letter{
    text-transform: uppercase;
}
.mobile-date-widget-card > .header > img{
    height: 30px;
}
.mobile-date-widget-card > .content{
    width: calc(100% - 22px);
    height: auto;
    padding: 0px 10px;
    /* min-height: 100px; */
    /* display: none; */
}


.mobile-date-widget-card > .content > .widget{
    border-bottom: thin dashed rgba(0, 0, 0, 0.2);
    width: calc(100% - 20px);
    padding: 3px 10px;
    margin: 5px 0px;
}
.mobile-date-widget-card > .content > .widget:hover{
    background-color: var(--BG_WHITE_COLOR);
    border-bottom: thin solid rgba(0, 0, 0, 0.2);
}
.mobile-date-widget-card > .content > .widget:last-child{
    border: none;
}
.mobile-date-widget-card > .content > .widget > img{
    height: 20px;
    width: 20px;
    padding: 5px;
    /* background-color: red; */
}
.mobile-date-widget-card > .content > .widget > img:hover,
.mobile-date-widget-card > .content > .widget > img:active{
    transform: rotate(25deg);
}
.mobile-date-widget-card > .content > .widget > .details{
    font-family: 'Architects Daughter', cursive;
    width: 100%;
}
.mobile-date-widget-card > .content > .widget > .details > p{
    font-size: 0.9em;
}
.mobile-date-widget-card > .content > .widget > .empty{
    font-size: 0.8em;
}







.mobile-date-widget-card-report{
    width: calc(100% - 20px);
    height: auto;
    padding: 10px;
    margin-top: 10px;
}
.mobile-date-widget-card-report > .header{
    width: calc(100% - 20px);
    height: auto;
    padding: 3px 10px;
    color: white;
}
.mobile-date-widget-card-report > .header span{
    /* background-color: red; */
    width: calc(100% - 60px);
}
.mobile-date-widget-card-report > .header span::first-letter{
    text-transform: uppercase;
}

.mobile-date-widget-card-report > .header > img{
    height: 30px;
}
.mobile-date-widget-card-report > .content{
    width: calc(100% - 22px);
    height: auto;
    padding: 0px 10px;
    min-height: 100px;
    /* display: none; */
}
.mobile-date-widget-card-report > .content > .widget{
    border-bottom: thin dashed rgba(0, 0, 0, 0.2);
    margin-top: 10px;
}
.mobile-date-widget-card-report > .content > .widget:last-child{
    border-top: thin solid black;
    border-bottom: none;
    margin-bottom: 10px;
    font-weight: bold;
}
.mobile-date-widget-card-report > .content > .widget:nth-last-child(2){
    border-bottom: none;
}


.mdwcr-handler{
    width: calc(100% - 20px);
    height: auto;
    /* min-height: 20px; */
    /* background-color: red; */
}
.mdwcr-handler > .widget{
    height: 20px;
    width: calc( ( 100% / 4 ) - 5px );
    /* background-color: red; */
    border-radius: 3px;
    color: white;
    font-size: 0.8em;
}
.mdwcr-handler > .widget.active{
    /* filter: grayscale(90%); */
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}






.mobile-date-widget-card.essential > .header,
.mobile-date-widget-card-report.essential > .header,
.mdwcr-handler > .widget.essential{
    background-color: var(--ORANGE_PALETTE);
}
.mobile-date-widget-card.essential > .content,
.mobile-date-widget-card-report.essential > .content{
    border: 1px solid var(--ORANGE_PALETTE);
    
}

.mobile-date-widget-card.optional > .header,
.mobile-date-widget-card-report.optional > .header,
.mdwcr-handler > .widget.optional{
    background-color: var(--GREEN_PALETTE);
}
.mobile-date-widget-card.optional > .content,
.mobile-date-widget-card-report.optional > .content{
    border: 1px solid var(--GREEN_PALETTE);
    
}

.mobile-date-widget-card.leisure > .header,
.mobile-date-widget-card-report.leisure > .header,
.mdwcr-handler > .widget.leisure{
    background-color: var(--PINK_PALETTE);
}
.mobile-date-widget-card.leisure > .content,
.mobile-date-widget-card-report.leisure > .content{
    border: 1px solid var(--PINK_PALETTE);
    
}

.mobile-date-widget-card.extras > .header,
.mobile-date-widget-card-report.extras > .header,
.mdwcr-handler > .widget.extras{
    background-color: var(--BLUE_PALETTE);
}
.mobile-date-widget-card.extras > .content,
.mobile-date-widget-card-report.extras > .content{
    border: 1px solid var(--BLUE_PALETTE);
    
}

.mobile-date-widget-card > .header.total{
    border: 1px solid grey;
}
.mobile-date-widget-card > .header.total  > .title{
    background-color: grey;
}