@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap);
:root{
  --MAX_WIDTH: 960px;

  
  --MAIN_COLOR: #fdcb30;
  --SUB_COLOR: #fba516;
  --SHADE_COLOR: #f58511;
  

  --BTN_COLOR: goldenrod;
  --BTN_COLOR_HOVER: rgb(194, 151, 44);
  --MESSAGE_MY_COLOR: goldenrod;

  

  /* --BG_WHITE_COLOR: #FEFEFE; */
  --BG_WHITE_COLOR: #f7f7f7;

  
  --TEXT_COLOR: white;
  --TITLE_COLOR: #f58511;

  --ORANGE_PALETTE: #d15421;
  --GREEN_PALETTE: #7FA559;
  --PINK_PALETTE: #BA447F;
  --RED_PALETTE: #B24C4C;
  --YELLOW_PALETTE: #B2B24C;
  --PURPLE_PALETTE: #5959A5;
  --BLUE_PALETTE: #59A5A5;


  
}

@font-face {
  font-family: 'avant-garde-lg';
  src: url(/static/media/AvantGardeLG.959475bd.ttf);
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'avant-garde-md';
  src: url(/static/media/AvantGardeMD.57e5551b.otf);
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'slim';
  src: url(/static/media/slim.43eb9ab9.woff2);
  font-weight: normal;
  font-style: normal;
}


/* base styles */
body {
  font-family: Poppins, sans-serif;
  margin: 0;
  font-size: 1.1em;
}
ul, li, p, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}


#root::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  /* position: relative;
  right: 20px; */
}
/* Track */
#root::-webkit-scrollbar-track {
  /* background: rgba(255, 255, 255, 0.07);  */
  background: goldenrod;
  background: var(--BTN_COLOR); 
  border-radius: 5px;
}
/* Handle */
#root::-webkit-scrollbar-thumb {
  /* background: #888;  */
  background: goldenrod;
  background: var(--BTN_COLOR); 
  border-radius: 5px;
  cursor: grab;
}
/* Handle on hover */
#root::-webkit-scrollbar-thumb:hover {
  /* background: #555;  */
  background: rgb(194, 151, 44);
  background: var(--BTN_COLOR_HOVER); 
}



.flex-row-center-center{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.flex-row-center-start{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.flex-row-center-end{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.flex-row-center-between{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.flex-row-center-around{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.flex-row-center-even{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.flex-row-start-center{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.flex-row-start-start{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.flex-row-start-end{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
}
.flex-row-start-between{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.flex-row-start-around{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
}
.flex-row-start-even{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
}

.flex-row-end-center{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}
.flex-row-end-start{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}
.flex-row-end-end{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}
.flex-row-end-between{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.flex-row-end-around{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-around;
}
.flex-row-end-even{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-evenly;
}



.flex-col-center-center{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.flex-col-center-start{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.flex-col-center-end{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.flex-col-center-between{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.flex-col-center-around{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.flex-col-center-even{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.flex-col-start-center{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.flex-col-start-start{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.flex-col-start-end{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}
.flex-col-start-between{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.flex-col-start-around{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}
.flex-col-start-even{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}

.flex-col-end-center{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.flex-col-end-start{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
.flex-col-end-end{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}
.flex-col-end-between{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.flex-col-end-around{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
}
.flex-col-end-even{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
}






button, .btn{
  background-color: goldenrod;
  background-color: var(--BTN_COLOR);
  color: white;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
}
button:active{
  background-color: rgb(194, 151, 44);
  background-color: var(--BTN_COLOR_HOVER);
}


progress {
  border-radius: 3px; 
  width: 80%;
}
progress::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
  border-radius: 0px 5px 5px 0px;
}
progress::-webkit-progress-value {
  background-color: goldenrod;
  border-radius: 0px 5px 5px 0px;
}
progress::-moz-progress-bar {
/* style rules */
  background-color: #eee;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
  border-radius: 0px 5px 5px 0px;
}
progress::-moz-progress-value {
  background-color: goldenrod;
  border-radius: 0px 5px 5px 0px;
}


*:focus {
  outline: 0;
}
*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}


*[message] {
  position: relative;
  color: #00F;
  cursor: help;
}

*[message]:hover::after,
*[message]:focus::after {
  content: attr(message);
  position: absolute;
  left: 0;
  top: 24px;
  min-width: 200px;
  border-radius: 10px;
  background-color: #f7f7f7;
  background-color: var(--BG_WHITE_COLOR);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  padding: 12px;
  color: #000000;
  font-size: 14px;
  z-index: 999999;
  font-family: slim;
  letter-spacing: 1.2px;
  line-height: 1.3em;
}




/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  /* position: relative;
  right: 20px; */
}
/* Track */
::-webkit-scrollbar-track {
  /* background: rgba(255, 255, 255, 0.07);  */
  background: #f7f7f7;
  background: var(--BG_WHITE_COLOR); 
  border-radius: 5px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #888;  */
  background: goldenrod;
  background: var(--BTN_COLOR); 
  border-radius: 5px;
  cursor: grab;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #555;  */
  background: goldenrod;
  background: var(--BTN_COLOR); 
}




.pdftest{
  height: 100%;
  width: 100%;
  text-align: center;
}
.pdftestError{
  padding: 10px;
}
.toast-container{
    position: fixed;
    bottom: 10vh;
    width: 70vw;
    max-width: 70vw;
    left: calc( 30vw / 2 );
}
.toast-container span{
    max-width: var(--MAX_WIDTH);
    background-color: var(--MAIN_COLOR);
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
    letter-spacing: 1.2px;
    word-spacing: 2px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    font-size: 0.8em;
    text-align: center;
    text-overflow: ellipsis;
}



#global-prompt{
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: rgba(255, 255, 255, 0.2);
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
    display: flex;
    align-items: center;
    justify-content: center;
}
#global-prompt > .container{
    width: calc(100% - 40px);
    max-width: var(--MAX_WIDTH);
    background-color: var(--MAIN_COLOR);
    border-radius: 5px;
    color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    text-align: center;
    text-overflow: ellipsis;
    padding: 20px 10px;
}


/* #global-prompt > .container > .password{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-top: 10px;
}
#global-prompt > .container > .password > button{
    width: 30%;
    font-size: 1.2em;
    border-radius: 0px 5px 5px 0px;
    box-shadow: none;
}
#global-prompt > .container > .password > input{
    width: calc(100% - 20px);
    border: none;
    padding-left: 20px;
    font-size: 1.2em;
    border-radius: 5px 0px 0px 5px;
    box-shadow: none;
    background-color: var(--BG_WHITE_COLOR);
    height: 100%;
}
   */


  @media (max-width: 768px) { /* mobile */
    :root{
        --HTITLE_SIZE: 3vw;
       
    }

    .toast-container{
        position: fixed;
        bottom: 20px;
        width: 70vw;
        max-width: 70vw;
        left: calc( 30vw / 2 );
    }
    .toast-container span{
        background-color: var(--MAIN_COLOR);
        padding: 5px 10px;
        border-radius: 5px;
        color: white;
        text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
        letter-spacing: 1.2px;
        word-spacing: 2px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
        font-size: 0.8em;
        text-align: center;
    }


    
    
    
    

}

.custom-form .field{
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.custom-form .field > input{
    width: calc(100% - 15px);
    padding: 5px;
    padding-left: 10px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
    border-radius: 5px;
}
.custom-form .field > span{
    width: 100%;
    text-align: start;
}
.custom-form .field > span > .red{
    color: red;
}
.custom-form .field > span > .purple{
    color: purple;
}
.custom-form .field > span > .premium{
    color: goldenrod;
}
/* .custom-form .field > span > .right{
color: red;
} */
.custom-form .field > iframe{
    width: 100%;
    height: 300px;
}
.custom-form .field > select{
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
}
.custom-form .field > textarea{
    width: calc(100% - 15px);
    height: 100px;
    padding: 5px;
    padding-left: 10px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
    border-radius: 5px;
    resize: none;
}
.custom-form > button{
    width: 100%;
    font-size: 1.2em;
    margin-top: 10px;
}
.custom-form * button{
    width: 100%;
    font-size: 1.2em;
    margin-top: 10px;
}
.custom-form .input-button-combo{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-bottom: 10px;
}
.custom-form .input-button-combo > button{
    width: 30%;
    font-size: 1.2em;
    border-radius: 0px 5px 5px 0px;
    box-shadow: none;
}
.custom-form .input-button-combo > input{
    width: calc(70% - 20px);
    border: none;
    padding: 5px 0px;
    padding-left: 20px;
    font-size: 1.2em;
    border-radius: 5px 0px 0px 5px;
    box-shadow: none;
    background-color: var(--BG_WHITE_COLOR);
    height: 100%;
}


.custom-form > .field input:active,
.custom-form > .field input:focus{
    /* border: thick solid blue; */
    border: none;
    outline: none;
}

.custom-form > .title{
    width: 100%;
    text-align: center;
    font-size: 1.2em;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}



.custom-form > .divider-message{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0px;
    position: relative;
    z-index: inherit;
}
.custom-form > .divider-message > hr{
    width: 100%;
    position: absolute;
    z-index: -5;
}
.custom-form > .divider-message > span{
    /* z-index: 5; */
    background-color: white;
    padding: 0px 10px;
    font-size: 0.8em;
}
.login-container{
  width: 100%;
  /* margin: 10px 0px; */
}
.login-container form{
  width: calc(100% - 40px);
  max-width: 450px;
}

.login-google{
  background-color: #4286f6;
}
.login-facebook{
  background-color: #3e5b99;
}
.login-apple{
  background-color: black;
}
.login-container > button{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: calc(100% - 40px);
  max-width: 450px;
  font-size: 1.2em;
  margin-top: 10px;
}

.login-container button:active{
  background-color: grey;
}

.login-container p.msg{
  color: grey;
}
.login-container p.msg > a{
  color: var(--MAIN_COLOR);
  font-weight: bold;
  letter-spacing: 1.2px;
  text-decoration: none;
}
.signup-container{
  width: 100%;
  /* margin: 10px 0px; */
}
.signup-container form{
  width: calc(100% - 40px);
  max-width: 450px;
}

.signup-google{
  background-color: #4286f6;
}
.signup-facebook{
  background-color: #3e5b99;
}
.signup-apple{
  background-color: black;
}
.signup-container > button{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: calc(100% - 40px);
  max-width: 450px;
  font-size: 1.2em;
  margin-top: 10px;
}

.signup-container button:active{
  background-color: grey;
}

.signup-container p.msg{
  color: grey;
}
.signup-container p.msg > a{
  color: var(--MAIN_COLOR);
  font-weight: bold;
  letter-spacing: 1.2px;
  text-decoration: none;
}
/* navbar styles */
.navbar {
  width: calc(100%);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.navbar > .container{
  width: calc(100% - 20px);
  padding: 10px 10px;
  /* max-width: var(--MAX_WIDTH); */
  /* background-color: red; */
  position: relative;
  
}
.navbar > .container img{
  height: 40px;
  width: 40px;
  cursor: pointer;
}
.navbar > .container .logo{
  margin-left: 10px;
}
.navbar > .container .buttonlogo{
  margin-left: 10px;
  height: 50px;
  width: 100px;
  object-fit: contain;
}
.navbar > .container .brandname{
  margin-left: 10px;
  font-size: 1.2em;
  font-family: 'avant-garde-lg';
  color: var(--MAIN_COLOR);
  letter-spacing: 4px;
  text-decoration: none;
}
.navbar > .container > .left{
  text-decoration: none;
  margin-left: 10px;
}
.navbar > .container .right{
  /* height: 100%; */
  width: auto;
  position: relative;
  /* background-color: red; */
}
.navbar > .container > .right img{
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin-right: 10px;
}
.navbar > .container .userprofile{
  /* background-color: red; */
  /* height: 50px;
  width: 50px; */
  margin-left: 10px;
}
.navbar > .container .username{
  /* position: absolute; */
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: var(--MAIN_COLOR);
  font-family: 'avant-garde-lg';
  font-weight: bold;
  font-size: 1.2em;
  color: white;
  letter-spacing: 2px;
  border: 1px solid white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.2);
  margin-right: 10px;
  cursor: pointer;
}


.navbar > .container > .right-popup{
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  top: 0px;
  left: 0px;
  width: calc(100% - 20px);
  height: calc(100% - 10px);
  padding: 5px 10px;
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
}
.navbar > .container > .right-popup img{
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin-right: 10px;
}














.navpop{
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 9000;
}
.navpop > .container{
  height: 100vh;
  max-height: 100vh;
  width: 350px;
  
  background-color: white;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  overflow-y: auto;
}

.navpop > .container > .close{
  height: 40px;
  width: calc(100% - 20px);
  padding: 0px 10px;
  font-size: 1.2em;
  /* background-color: red; */
  border-bottom: thin solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}
.navpop > .container > .close > img{
  height: 30px;
  width: 30px;
  cursor: pointer;
}
/* .navpop > .container > .close:hover > i{
  font-size: 1.4em;
} */


.navpop > .container > .widget-con{
  height: auto;
  /* max-height: calc( 100% - 40px ); */
  width: calc(100%);
  /* padding-left: 20px; */
  /* background-color: red; */
}

.navpop > .container > .widget-con > .widget{
  width: calc(100% - 40px);
  padding: 15px 20px;
  margin-right: 20px;
  position: relative;
  /* background-color: red; */
  cursor: pointer;
  margin-bottom: 1px;
  text-decoration: none;
  color: black;
}
.navpop > .container > .widget-con > .widget:hover{
  background-color: var(--BG_WHITE_COLOR);
}
.navpop > .container > .widget-con.super > .widget{
  font-weight: bold;
}
.navpop > .container > .widget-con> .widget > img{
  margin-right: 20px;
  height: 30px;
  width: 30px;
}
.navpop > .container > .widget-con > .widget > span{
  font-family: avant-garde-md;
  cursor: pointer;
}
.navpop > .container > .widget-con.super > .widget > span{
  letter-spacing: 0.1em;
}
.navpop > .container > .widget-con> .widget > hr{
  height: 0.001px;
  width: calc(100% - 30px);
  position: absolute;
  bottom: -10px;
  left: 20px;
  cursor: pointer;
}


#svg_logo{
  margin-left: 10px;
  width: 120px;
  cursor: pointer;
}

@-webkit-keyframes float1 {
  0% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
  3% {
    -webkit-transform: translatey(-20px);
            transform: translatey(-20px);
  }
  9% {
    -webkit-transform: translatey(20px);
            transform: translatey(20px);
  }
  12% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}

@keyframes float1 {
  0% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
  3% {
    -webkit-transform: translatey(-20px);
            transform: translatey(-20px);
  }
  9% {
    -webkit-transform: translatey(20px);
            transform: translatey(20px);
  }
  12% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}


@media (max-width: 768px) { /* mobile */
  .navpop > .container{
    height: 100vh;
    max-height: 100vh;
    width: 100vw;
    
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    overflow-y: auto;
  }

}
.dashboard-container {
    max-width: var(--MAX_WIDTH);
    margin: 30px auto;
    min-height: 350px;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.dashboard-container-header{
    height: 60px;
    width: 100%;
    background-color: var(--MAIN_COLOR);
    border-radius: 10px;
}
.dashboard-container-header > img{
    cursor: pointer;
    padding: 0px 10px;
}
.dashboard-container-header > .title-con{
    min-width: 70%;
    height: 100%;
    padding: 0px 20px;
    cursor: pointer;
    position: relative;
}
.dashboard-container-header .month{
    font-size: 1.2em;
    color: white;
    cursor: pointer;
    padding: 5px;
}
.dashboard-container-header .month > p{
    font-size: 0.6em;
    margin-left: 10px;
}
.dashboard-container-header .week{
    font-size: 1.2em;
    color: white;
    cursor: pointer;
    padding: 5px 50px;
}
.dashboard-container-header > .title-con > .frag > p{
    color: green;
    font-size: 3em;
    cursor: default;
}
.dashboard-container-header > .title-con > .frag > p.return{
    color: red;
    cursor: help;
}
.dashboard-container-header > .title-con > .frag > img{
    height: 30px;
    width: 30px;
    margin-left: 20px;
    cursor: pointer;
}
.dashboard-container-header > .title-con > img{
    height: 30px;
    width: 30px;
    margin-left: 20px;
    cursor: pointer;
}

.dashboard-container-header > .title-con > .popup{
    width: calc(100% - 40px);
    height: auto;
    padding: 20px;
    position: absolute;
    top: 100%;
    left: 0px;
    background-color: var(--BG_WHITE_COLOR);
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    /* flex-col-center-start */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 100;
}
.dashboard-container-header > .title-con > .popup.show{
    display: flex;
}
.dashboard-container-header > .title-con > .popup > input[type="month"]{
    width: 90%;
    border: none;
    padding: 10px 20px;
    font-size: 1.2em;
    font-family: Poppins, sans-serif;
}
.dashboard-container-header > .title-con > .popup > input[type="month"]:active,
.dashboard-container-header > .title-con > .popup > input[type="month"]:focus{
    border-color: var(--MAIN_COLOR);
    border: thin solid black;

}
.dashboard-container-header > .title-con > .popup > button{
    width: 90%;
    padding: 10px 20px;
    margin-top: 20px;
}

.dashboard-container-content{
    /* background: red; */
    width: 100%;
    height: auto;
    margin-top: 10px;
}
.dashboard-container-content > .widget{
    height: auto;
    width: calc( (100% / 7) - 5px );
    /* background: red; */
}

.dashboard-container-content > .widget > .header{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    color: black;
    margin-bottom: 10px;
}
.dashboard-container-content > .widget > .header > p{
    margin-left: 5px;
}
.dashboard-container-content > .widget > .container{
    width: 100%;
    height: auto;
    /* background: red; */
    border: 1px solid pink;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    margin-bottom: 10px;
}
.dashboard-container-content > .widget > .container.closed{
    border-radius: 10px;
}

.dashboard-container-content > .widget > .container > .title{
    width: 100%;
    padding: 5px 0px;
    background-color: pink;
    text-align: center;
    color: white;
    cursor: pointer;
}
.dashboard-container-content > .widget > .container > .title > .numOfTr{
    font-size: 0.9em;
}
.dashboard-container-content > .widget  > .container > .title > img{
    height: 20%;
    width: 20%;
    cursor: pointer;
}
.dashboard-container-content > .widget > .container > .trans{
    width: calc(100% - 10px);
    height: 100%;
    /* background-color: red; */
}
.dashboard-container-content > .widget > .container > .trans > .widget{
    width: 100%;
    margin-bottom: 10px;
    border-bottom: thin dashed rgba(0, 0, 0, 0.2);
    font-family: 'Architects Daughter', cursive;
}
.dashboard-container-content > .widget > .container > .trans > .widget:last-child{
    border-bottom: none;
}
.dashboard-container-content > .widget > .container > .trans > .widget > .amount{
    font-size: 0.9em;
    width: 100%;
}
.dashboard-container-content > .widget > .container > .trans > .widget > .title:first-letter {
    text-transform: uppercase;
}
.dashboard-container-content > .widget > .container > .trans > .widget > .amount > img{
    height: 15px;
    width: 15px;
    padding: 5px;
    cursor: pointer;
}
.dashboard-container-content > .widget > .container > .trans > .widget > .amount > img:hover{
    -webkit-transform: rotate(25deg);
            transform: rotate(25deg);
}


.dashboard-container-content > .widget > .container.essential{
    border: 1px solid var(--ORANGE_PALETTE);
}
.dashboard-container-content > .widget > .container.essential  > .title{
    background-color: var(--ORANGE_PALETTE);
}

.dashboard-container-content > .widget > .container.optional{
    border: 1px solid var(--GREEN_PALETTE);
}
.dashboard-container-content > .widget > .container.optional  > .title{
    background-color: var(--GREEN_PALETTE);
}

.dashboard-container-content > .widget > .container.leisure{
    border: 1px solid var(--PINK_PALETTE);
}
.dashboard-container-content > .widget > .container.leisure  > .title{
    background-color: var(--PINK_PALETTE);
}

.dashboard-container-content > .widget > .container.extras{
    border: 1px solid var(--BLUE_PALETTE);
}
.dashboard-container-content > .widget > .container.extras  > .title{
    background-color: var(--BLUE_PALETTE);
}

.dashboard-container-content > .widget > .container.total{
    border: 1px solid grey;
}
.dashboard-container-content > .widget > .container.total  > .title{
    background-color: grey;
}




.dashboard-container-content > .widget.disabled{
    cursor: not-allowed;
}
.dashboard-container-content > .widget.disabled  > .container{
    background-color: rgb(226, 226, 226);
    border: 1px solid var(--BG_WHITE_COLOR);
    cursor: not-allowed;
}
.dashboard-container-content > .widget.disabled  > .container > .title{
    background-color: var(--BG_WHITE_COLOR);
    color: grey;
    cursor: not-allowed;
}





.dashboard-container-content > .weekly-summary-con{
    width: calc( (100% / 4) - 5px );
    height: 100%;
}
.dashboard-container-content > .weekly-summary-con > .title{
    width: calc(100% - 10px);
    min-height: 50px;
    padding: 5px;
    text-align: center;
    color: white;
}
.dashboard-container-content > .weekly-summary-con > .content{
    min-height: 100px;
    width: calc(100% - 10px);
}
.dashboard-container-content > .weekly-summary-con > .content > .widget{
    width: calc(100%);
    border-bottom: thin dashed rgba(0, 0, 0, 0.2);
    margin-top: 5px;
}
.dashboard-container-content > .weekly-summary-con > .content > .widget:last-child{
    border-bottom: none;
    font-weight: bold;
    border-top: thin solid black;
}
.dashboard-container-content > .weekly-summary-con > .content > .widget:nth-last-child(2){
    border-bottom: none;
}
.dashboard-container-content > .weekly-summary-con > .content > .widget span{
    font-size: 0.8em;
}

.dashboard-container-content > .weekly-summary-con > .content > .widget.empty > span{
    color: grey;
}


.dashboard-container-content > .weekly-total{
    width: calc(100% - 40px);
    height: auto;
    padding: 10px 20px;
    background-color: var(--MAIN_COLOR);
    color: white;
    margin-top: 20px;
}
.dashboard-container-content > .weekly-total > div > span:nth-child(2){
    font-size: 1.2em;
    border: 3px solid white;
    padding: 3px 10px;
    border-radius: 10px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}




.dashboard-container-content > .weekly-summary-con.essential{
    border: 1px solid var(--ORANGE_PALETTE);
}
.dashboard-container-content > .weekly-summary-con.essential  > .title{
    background-color: var(--ORANGE_PALETTE);
}

.dashboard-container-content > .weekly-summary-con.optional{
    border: 1px solid var(--GREEN_PALETTE);
}
.dashboard-container-content > .weekly-summary-con.optional  > .title{
    background-color: var(--GREEN_PALETTE);
}

.dashboard-container-content > .weekly-summary-con.leisure{
    border: 1px solid var(--PINK_PALETTE);
}
.dashboard-container-content > .weekly-summary-con.leisure  > .title{
    background-color: var(--PINK_PALETTE);
}

.dashboard-container-content > .weekly-summary-con.extras{
    border: 1px solid var(--BLUE_PALETTE);
}
.dashboard-container-content > .weekly-summary-con.extras  > .title{
    background-color: var(--BLUE_PALETTE);
}



.dashboard-container-content > .monthly-summary-nav > span{
    background-color: grey;
    color: rgba(252, 252, 252, 0.5);
    padding: 5px 10px;
    margin: 2px 0px;
    cursor: pointer;
}
.dashboard-container-content > .monthly-summary-nav > div{
    width: 1px;
    height: 100%;
}
.dashboard-container-content > .monthly-summary-nav > span.active{
    background-color: var(--BTN_COLOR);
    border: 2px solid var(--BTN_COLOR);
    color: white;
}
.dashboard-container-content > .monthly-summary-content{
    margin-top: 10px;
    width: 100%;
    height: auto;
}

.dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con{
    width: 100%;
    height: auto;
}
.dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .left-con{
    width: calc(65% - 10px);
    height: auto;
}
.dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .right-con{
    width: 35%;
    height: auto;
}


.dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .left-con > .title{
    font-size: 1.2em;
    background-color: var(--MAIN_COLOR);
    color: white;
    width: 100%;
    text-align: center;
}
.dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .left-con > .list-con{
    width: 100%;
    height: auto;
    
}
.dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .left-con > .list-con > .widget{
    width: calc(100% - 20px);
    padding: 5px 10px;
    background-color: var(--BG_WHITE_COLOR);
    margin-top: 10px;
    font-family: 'Architects Daughter', cursive;
}
.dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .left-con > .list-con > .widget.total{
    background-color: transparent;
    font-family: Poppins, sans-serif;
}
.dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .left-con > .list-con > .widget > span > img{
    height: 20px;
    width: 20px;
    cursor: pointer;
}
.dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .left-con > .list-con > .widget > span > img:hover,
.dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .left-con > .list-con > .widget > span > img:active{
    -webkit-transform: rotate(25deg);
            transform: rotate(25deg);
}

.dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .right-con > form{
    width: calc(100% - 20px);
    padding: 10px;
    background-color: var(--MAIN_COLOR);
    color: white;
    border-radius: 15px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}
.dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .right-con > form button{
    box-shadow: none;
}

.dashboard-container-content > .monthly-summary-content > .title{
    font-size: 1.4em;
    width: 70%;
    background-color: var(--MAIN_COLOR);
    color: white;
}
.dashboard-container-content > .monthly-summary-content > .title button{
    padding: 5px 10px;
    margin-left: 20px;
}
.dashboard-container-content > .monthly-summary-content > .goal-field{
    width: 70%;
}
.dashboard-container-content > .monthly-summary-content > .goal-field textarea{
    width: calc(100% - 20px);
    min-height: 50px;
    resize: none;
    font-family: Poppins, sans-serif;
    padding: 10px;
    font-size: 1.1em;
}
.dashboard-container-content > .monthly-summary-content > .goal-field-amount{
    width: 70%;
}
.dashboard-container-content > .monthly-summary-content > .goal-field-amount input,
.dashboard-container-content > .monthly-summary-content > .goal-field-amount span.total{
    font-size: 3em;
    width: 40%;
    padding: 10px 20px;
    text-align: center;
    padding-right: 10px;
    border: none;
    background-color: var(--MAIN_COLOR);
    color: white;
    border-radius: 15px;
}
.dashboard-container-content > .monthly-summary-content > .goal-field-amount input::-webkit-outer-spin-button,
.dashboard-container-content > .monthly-summary-content > .goal-field-amount input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.dashboard-container-content > .monthly-summary-content > .goal-field-amount input[type=number] {
  -moz-appearance: textfield;
}

.dashboard-container-content > .monthly-summary-content > .goal-field-summary{
    width: 70%;
}
.dashboard-container-content > .monthly-summary-content > .goal-field-summary > .widget-con{
    width: 100%;
}
.dashboard-container-content > .monthly-summary-content > .goal-field-summary > .widget-con > .widget{
    background-color: var(--MAIN_COLOR);
    color: white;
    width: calc(30% - 20px);
    padding: 10px;
    text-align: center;
    border-radius: 15px;
}
.dashboard-container-content > .monthly-summary-content > .goal-field-summary > .widget-con > .widget > .title{
    font-size: 0.95em;
    width: 100%;
}
.dashboard-container-content > .monthly-summary-content > .goal-field-summary > .widget-con > .widget > .amount{
    font-size: 3em;
}


.summary-chart-grid4{
    display: grid;
    grid-template-columns: 1fr 1fr;
}




.monthly-summary-significant-expenses-con{
    width: calc(450px - 20px);
    height: auto;
    /* background-color: var(--BG_WHITE_COLOR); */
    padding: 10px 10px 20px 10px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    margin-bottom: 20px;
}
.monthly-summary-significant-expenses-con > .title{
    width: calc(100% - 20px);
    padding: 3px 10px;
    font-size: 1.2em;
    background-color: var(--MAIN_COLOR);
    color: white;
    border-radius: 10px 10px 0px 0px;
}
.monthly-summary-significant-expenses-con > .widget{
    width: calc(100% - 20px);
    margin-top: 10px;
    border-bottom: thin dashed rgba(0, 0, 0, 0.2);
}
.monthly-summary-significant-expenses-con > .widget.super{
    font-weight: bold;
    border-bottom: none;
}
.monthly-summary-significant-expenses-con > .widget:first-child{
    border-bottom: none;
}
.monthly-summary-significant-expenses-con > .widget:last-child{
    border-bottom: none;
    border-top: thin solid rgba(0, 0, 0, 0.2);
}
.monthly-summary-significant-expenses-con > .widget:nth-last-child(2){
    border-bottom: none;
}


.monthly-summary-significant-expenses-con > .footer{
    width: 100%;
    height: auto;
    margin-top: 20px;
}

.monthly-summary-significant-expenses-con > .footer > .footwidget{
    padding: 5px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 0px 5px;
}
.monthly-summary-significant-expenses-con > .footer > .footwidget.active{
    background-color: var(--MAIN_COLOR);
    box-shadow: 0px 0px 3px 0px black;
}
.monthly-summary-significant-expenses-con > .footer > img{
    cursor: pointer;
}




















/* ///////////////////////// MOBILE */











@media (max-width: 768px) { /* mobile */
    :root{
        --HTITLE_SIZE: 3vw;
        --HSTITLE_SIZE: 4.4vw;
        --MTITLE_SIZE: 1.6em;
        --MSTITLE_SIZE: 1.4em;
        --TITLE_SIZE: 1.3em;
        --TEXT_SIZE: 1em;
        --STEXT_SIZE: 0.9em;
    }


    .dashboard-container {
        max-width: 100vw;
        margin: 0px 0px 30px 0px;
        min-height: 350px;
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    
    .dashboard-container-header{
        width: calc(100% - 20px);
        margin-top: 10px;
    }
    .dashboard-container-header > img{
        cursor: pointer;
        padding: 0px 0px;
        /* background-color: red; */
        height: 30px;
        width: 30px;
    }
    .dashboard-container-header > .title-con{
        min-width: calc(100% - 70px);
        max-width: calc(100% - 70px);
        height: 100%;
        /* background-color: red; */
        padding: 0px 0px;
        cursor: pointer;
        position: relative;
    }
    .dashboard-container-header > .title-con > img{
        height: 30px;
        width: 30px;
        margin-left: 0px;
        cursor: pointer;
    }

    .dashboard-container-header > .title-con > .frag > img{
        height: 30px;
        width: 30px;
        margin-left: 20px;
        cursor: pointer;
    }


    .dashboard-container-content > .weekly-total{
        width: calc(100% - 40px);
        height: auto;
        padding: 10px;
        background-color: var(--MAIN_COLOR);
        color: white;
        margin-top: 20px;
    }
    .dashboard-container-content > .weekly-total > div > span:nth-child(2){
        font-size: 1.2em;
        border: 3px solid white;
        padding: 3px 10px;
        border-radius: 10px;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    }



    .dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con{
        position: relative;
    }
    .dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .left-con{
        width: calc(100% - 20px);
        height: auto;
    }
    .dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .right-con{
        width: 35%;
        height: auto;
    }
    .dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .left-con > .title{
        font-size: 1.2em;
        background-color: var(--MAIN_COLOR);
        color: white;
        width: calc(100% - 20px);
        text-align: center;
        padding: 5px 10px;
    }
    .dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .left-con > .title > img{
        height: 30px;
        width: 30px;
    }

    .dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .right-con{
        width: calc(100% - 20px);
        height: auto;
        position: absolute;
        left: 10px;
        top: 0px;
        z-index: 100;
    }
    .dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .right-con  > form > .title{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .right-con  > form > .title > img{
        height: 30px;
        width: 30px;
    }
    

    .dashboard-container-content > .monthly-summary-content > .title{
        font-size: 1.4em;
        width: calc(100% - 40px);
        padding: 5px 10px;
        background-color: var(--MAIN_COLOR);
        color: white;
        position: relative;
    }
    .dashboard-container-content > .monthly-summary-content > .title button{
        padding: 5px 10px;
        /* margin-left: 20px; */
        /* position: absolute; */
    }
    .dashboard-container-content > .monthly-summary-content > .goal-field{
        width: calc(100% - 20px);
    }
    .dashboard-container-content > .monthly-summary-content > .goal-field-amount{
        width: calc(100% - 20px);
    }
    .dashboard-container-content > .monthly-summary-content > .goal-field-summary{
        width: calc(100% - 20px);
    }
    .dashboard-container-content > .monthly-summary-content > .goal-field-summary > .widget-con > .widget{
        height: 100%;
        min-height: 120px;
    }
    .dashboard-container-content > .monthly-summary-content > .goal-field-summary > .widget-con > .widget > .amount{
        font-size: 1.4em;
        font-weight: bold;
    }
    .dashboard-container-content > .monthly-summary-content > .goal-field-amount span.total{
        font-size: 3em;
        width: calc( 100% - 20px );
        padding: 10px 20px;
        text-align: center;
        padding-right: 10px;
        border: none;
        background-color: var(--MAIN_COLOR);
        color: white;
        border-radius: 15px;
    }
    .summary-chart-grid4{
        display: flex;
        grid-template-columns: unset;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }


    .monthly-summary-significant-expenses-con{
        width: calc(100vw - 40px);
        height: auto;
        /* background-color: var(--BG_WHITE_COLOR); */
        padding: 10px 10px 20px 10px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
        border-radius: 15px;
    }
    .monthly-summary-significant-expenses-con > .footer > .footwidget{
        padding: 3px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.5);
        margin: 0px 3px;
    }

}
@media (min-width: 767px) and (max-width: 1280px) {  /* regular */
    :root{
        --HTITLE_SIZE: 2.6vw;
        --HSTITLE_SIZE: 1.7vw;
        
        --MTITLE_SIZE: 2em;
        --MSTITLE_SIZE: 1.7em;
        --TITLE_SIZE: 1.5em;
        --TEXT_SIZE: 1.3em;
        --STEXT_SIZE: 1em;
    }
    
    body{
        /* background-color: orange; */
    }

    
    
}
@media (min-width: 1281px) and (max-width: 2399px) { /* wide */
    :root{
        --HTITLE_SIZE: 3vw;
        --HSTITLE_SIZE: 1.7vw;
        --MTITLE_SIZE: 2.2em;
        --MSTITLE_SIZE: 2em;
        --TITLE_SIZE: 1.7em;
        --TEXT_SIZE: 1.5em;
        --STEXT_SIZE: 1.3em;
    }
    body{
        /* background-color: yellow; */
    }
    
   
}
@media (min-width: 2400px) {  /* ultrawide */
    :root{
        --HTITLE_SIZE: 3em;
        --HSTITLE_SIZE: 1.8em;
        --MTITLE_SIZE: 2.5em;
        --MSTITLE_SIZE: 2.3em;
        --TITLE_SIZE: 2em;
        --TEXT_SIZE: 1.8em;
        --STEXT_SIZE: 1.5em;
    }
    body{
        /* background-color: green; */
    }



}
.mobile-date-btn-con{
    width: calc(100% - 20px);
    height: auto;
    padding: 0px 10px;
    /* background-color: red; */
}
.mobile-date-btn-con > .widget{
    /* background-color: red; */
    width: calc( ( 100% / 7 ) - 5px );
    border-bottom: thin solid rgba(0, 0, 0, 0.2);
}
.mobile-date-btn-con > .widget.active{
    background-color: var(--MAIN_COLOR);
    color: white;
    border-radius: 5px 5px 0px 0px;
    border-bottom: none;
}
.mobile-date-btn-con > .widget.disabled{
    background-color: var(--BG_WHITE_COLOR);
    color: grey;
    border-radius: 5px 5px 0px 0px;
    border-bottom: none;
    cursor: not-allowed;
}
.mobile-date-widget-card{
    width: calc(100% - 20px);
    height: auto;
    padding: 10px;
    margin-top: 10px;
}
.mobile-date-widget-card > .header{
    width: calc(100% - 20px);
    height: auto;
    padding: 3px 10px;
    color: white;
}
.mobile-date-widget-card > .header span{
    /* background-color: red; */
    width: calc(100% - 60px);
}
.mobile-date-widget-card > .header span::first-letter{
    text-transform: uppercase;
}
.mobile-date-widget-card > .header > img{
    height: 30px;
}
.mobile-date-widget-card > .content{
    width: calc(100% - 22px);
    height: auto;
    padding: 0px 10px;
    /* min-height: 100px; */
    /* display: none; */
}


.mobile-date-widget-card > .content > .widget{
    border-bottom: thin dashed rgba(0, 0, 0, 0.2);
    width: calc(100% - 20px);
    padding: 3px 10px;
    margin: 5px 0px;
}
.mobile-date-widget-card > .content > .widget:hover{
    background-color: var(--BG_WHITE_COLOR);
    border-bottom: thin solid rgba(0, 0, 0, 0.2);
}
.mobile-date-widget-card > .content > .widget:last-child{
    border: none;
}
.mobile-date-widget-card > .content > .widget > img{
    height: 20px;
    width: 20px;
    padding: 5px;
    /* background-color: red; */
}
.mobile-date-widget-card > .content > .widget > img:hover,
.mobile-date-widget-card > .content > .widget > img:active{
    -webkit-transform: rotate(25deg);
            transform: rotate(25deg);
}
.mobile-date-widget-card > .content > .widget > .details{
    font-family: 'Architects Daughter', cursive;
    width: 100%;
}
.mobile-date-widget-card > .content > .widget > .details > p{
    font-size: 0.9em;
}
.mobile-date-widget-card > .content > .widget > .empty{
    font-size: 0.8em;
}







.mobile-date-widget-card-report{
    width: calc(100% - 20px);
    height: auto;
    padding: 10px;
    margin-top: 10px;
}
.mobile-date-widget-card-report > .header{
    width: calc(100% - 20px);
    height: auto;
    padding: 3px 10px;
    color: white;
}
.mobile-date-widget-card-report > .header span{
    /* background-color: red; */
    width: calc(100% - 60px);
}
.mobile-date-widget-card-report > .header span::first-letter{
    text-transform: uppercase;
}

.mobile-date-widget-card-report > .header > img{
    height: 30px;
}
.mobile-date-widget-card-report > .content{
    width: calc(100% - 22px);
    height: auto;
    padding: 0px 10px;
    min-height: 100px;
    /* display: none; */
}
.mobile-date-widget-card-report > .content > .widget{
    border-bottom: thin dashed rgba(0, 0, 0, 0.2);
    margin-top: 10px;
}
.mobile-date-widget-card-report > .content > .widget:last-child{
    border-top: thin solid black;
    border-bottom: none;
    margin-bottom: 10px;
    font-weight: bold;
}
.mobile-date-widget-card-report > .content > .widget:nth-last-child(2){
    border-bottom: none;
}


.mdwcr-handler{
    width: calc(100% - 20px);
    height: auto;
    /* min-height: 20px; */
    /* background-color: red; */
}
.mdwcr-handler > .widget{
    height: 20px;
    width: calc( ( 100% / 4 ) - 5px );
    /* background-color: red; */
    border-radius: 3px;
    color: white;
    font-size: 0.8em;
}
.mdwcr-handler > .widget.active{
    /* filter: grayscale(90%); */
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}






.mobile-date-widget-card.essential > .header,
.mobile-date-widget-card-report.essential > .header,
.mdwcr-handler > .widget.essential{
    background-color: var(--ORANGE_PALETTE);
}
.mobile-date-widget-card.essential > .content,
.mobile-date-widget-card-report.essential > .content{
    border: 1px solid var(--ORANGE_PALETTE);
    
}

.mobile-date-widget-card.optional > .header,
.mobile-date-widget-card-report.optional > .header,
.mdwcr-handler > .widget.optional{
    background-color: var(--GREEN_PALETTE);
}
.mobile-date-widget-card.optional > .content,
.mobile-date-widget-card-report.optional > .content{
    border: 1px solid var(--GREEN_PALETTE);
    
}

.mobile-date-widget-card.leisure > .header,
.mobile-date-widget-card-report.leisure > .header,
.mdwcr-handler > .widget.leisure{
    background-color: var(--PINK_PALETTE);
}
.mobile-date-widget-card.leisure > .content,
.mobile-date-widget-card-report.leisure > .content{
    border: 1px solid var(--PINK_PALETTE);
    
}

.mobile-date-widget-card.extras > .header,
.mobile-date-widget-card-report.extras > .header,
.mdwcr-handler > .widget.extras{
    background-color: var(--BLUE_PALETTE);
}
.mobile-date-widget-card.extras > .content,
.mobile-date-widget-card-report.extras > .content{
    border: 1px solid var(--BLUE_PALETTE);
    
}

.mobile-date-widget-card > .header.total{
    border: 1px solid grey;
}
.mobile-date-widget-card > .header.total  > .title{
    background-color: grey;
}
.modal-container-backdrop{
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 100000000000;
    background-color: rgba(255, 255, 255, 0.164);
    height: 100vh;
    width: 100vw;
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.modal-container{
    max-width: 50vw;
    min-width: 250px;
    padding: 30px 50px;
    background-color: white;
    margin-top: 20vh;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.2);
    text-align: center;
    position: relative;
}
.modal-container > img.modal-close{
    position: absolute;
    top: 10px;
    right: 10px;
    height: 30px;
    width: 30px;
    cursor: pointer;
}

.modal-container form{
    min-width: 500px;
}
.modal-container p{
    width: 100%;
    text-align: start;
    margin-bottom: 10px;
}
.modal-container .field{
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .modal-container .field > input{
    width: calc(100% - 15px);
    padding: 5px;
    padding-left: 10px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
    border-radius: 5px;
  }
  .modal-container .field > span{
      width: 100%;
      text-align: start;
  }
  .modal-container .field > span > .red{
    color: red;
  }
  .modal-container .field > span > .purple{
    color: purple;
  }
  .modal-container .field > span > .premium{
    color: goldenrod;
  }
  /* .modal-container .field > span > .right{
    color: red;
  } */
  .modal-container .field > iframe{
    width: 100%;
    height: 300px;
  }
  .modal-container .field > select{
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
  }
  .modal-container > button{
    width: 100%;
    font-size: 1.2em;
    margin-top: 10px;
  }
  .modal-container * button{
    width: 100%;
    font-size: 1.2em;
    margin-top: 10px;
  }
  .modal-container .input-button-combo{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .modal-container .input-button-combo > button{
    width: 30%;
    font-size: 1.2em;
    border-radius: 0px 5px 5px 0px;
    box-shadow: none;
  }
  .modal-container .input-button-combo > input{
    width: calc(70% - 20px);
    border: none;
    padding: 5px 0px;
    padding-left: 20px;
    font-size: 1.2em;
    border-radius: 5px 0px 0px 5px;
    box-shadow: none;
    background-color: var(--BG_WHITE_COLOR);
    height: 100%;
  }
  


  @media (max-width: 768px) { /* mobile */
    :root{
        --HTITLE_SIZE: 3vw;
       
    }

    .modal-container{
      max-width: calc(10vw - 150px);
      min-width: 75vw;
      padding: 30px 30px;
      background-color: white;
      margin-top: 10vh;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.2);
      text-align: center;
      position: relative;
  }
  .modal-container form{
      min-width: 100%;
  }


    
    
    
    

}
#adobe-dc-view{
    position: relative;
}

.howtouse-btn{
    align-self: center;
}
.howtouse-btn > a{
    color: white;
    text-decoration: none;
}
.website-container{
    width: 100%;
    max-width: 100vw;
    height: auto;
    margin-top: 20px;
    /* overflow: hidden; */
    /* background-color: red; */
}

#slime_svg {
    width:50vw;
    max-width: 500px;
    height:50vw;
    max-height: 500px;
}

#slime_svg path {
    cursor: pointer;
}
#slime_svg text{
    color: white;
    fill: white;
}

.svgContainer{
    height: auto;
}

.website-container > span{
    padding: 0px 10px;
    text-align: center;
}

.website-container > .tech {
    max-width: var(--MAX_WIDTH);
    /* background-color: red; */

}
.website-container > .tech > .widget{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* width: var(--MAX_WIDTH); */
    margin-top: 10px;
    /* background-color: red; */
}
.website-container > .tech > .widget span{
    min-width: 100px;
    text-align: end;
}
.website-container > .tech > .widget span > p{
    font-size: 0.8em;
}
.website-container > .tech > .widget img{
    max-height: 100px;
    width: 150px;
    object-fit: contain;
    /* background-color: red; */
    margin-left: 20px;
}


@media (max-width: 768px) { /* mobile */
    #slime_svg {
        width:80vw;
        height:80vw;
    }
  
  }
.terms-con{
    width: 100%;
    height: auto;
    margin: 20px 0px;
    font-size: 0.8em;
    /* background-color: red; */
}
.terms-con > .container{
    width: 100%;
    max-width: var(--MAX_WIDTH);
}




.terms-con h1,
.terms-con h2,
.terms-con h3,
.terms-con h4,
.terms-con h5,
.terms-con h6{
    padding: 0px;
    margin: 0px 20px;
    /* background-color: red; */
}

.terms-con p{
    width: calc(100% - 40px);
    padding: 10px 20px;
}
.terms-con b{

}
.terms-con h1{
    width: 100%;
    text-align: center;
    align-self: center;
    
}
.contact-container{
    width: 100%;
    /* background-color: red; */
    margin: 20px 0px;
}

.contact-container h1,
.contact-container h2,
.contact-container h3,
.contact-container h4,
.contact-container h5,
.contact-container h6{
    padding: 0px;
    margin: 0px 20px;
    /* background-color: red; */
}

.contact-container p{
    width: calc(100% - 40px);
    padding: 10px 20px;
    text-align: center;
}
.contact-container b{

}
.contact-container h1{
    width: 100%;
    text-align: center;
    align-self: center;
    
}


.contact-container form{
    width: calc(100% - 40px);
    max-width: 450px;
}
.contact-container input,
.contact-container textarea{
    width: calc(100% - 40px);
    padding: 5px 10px;
}
