/* navbar styles */
.navbar {
  width: calc(100%);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.navbar > .container{
  width: calc(100% - 20px);
  padding: 10px 10px;
  /* max-width: var(--MAX_WIDTH); */
  /* background-color: red; */
  position: relative;
  
}
.navbar > .container img{
  height: 40px;
  width: 40px;
  cursor: pointer;
}
.navbar > .container .logo{
  margin-left: 10px;
}
.navbar > .container .buttonlogo{
  margin-left: 10px;
  height: 50px;
  width: 100px;
  object-fit: contain;
}
.navbar > .container .brandname{
  margin-left: 10px;
  font-size: 1.2em;
  font-family: 'avant-garde-lg';
  color: var(--MAIN_COLOR);
  letter-spacing: 4px;
  text-decoration: none;
}
.navbar > .container > .left{
  text-decoration: none;
  margin-left: 10px;
}
.navbar > .container .right{
  /* height: 100%; */
  width: auto;
  position: relative;
  /* background-color: red; */
}
.navbar > .container > .right img{
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin-right: 10px;
}
.navbar > .container .userprofile{
  /* background-color: red; */
  /* height: 50px;
  width: 50px; */
  margin-left: 10px;
}
.navbar > .container .username{
  /* position: absolute; */
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: var(--MAIN_COLOR);
  font-family: 'avant-garde-lg';
  font-weight: bold;
  font-size: 1.2em;
  color: white;
  letter-spacing: 2px;
  border: 1px solid white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.2);
  margin-right: 10px;
  cursor: pointer;
}


.navbar > .container > .right-popup{
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  top: 0px;
  left: 0px;
  width: calc(100% - 20px);
  height: calc(100% - 10px);
  padding: 5px 10px;
  backdrop-filter: blur(6px);
}
.navbar > .container > .right-popup img{
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin-right: 10px;
}














.navpop{
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 9000;
}
.navpop > .container{
  height: 100vh;
  max-height: 100vh;
  width: 350px;
  
  background-color: white;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  overflow-y: auto;
}

.navpop > .container > .close{
  height: 40px;
  width: calc(100% - 20px);
  padding: 0px 10px;
  font-size: 1.2em;
  /* background-color: red; */
  border-bottom: thin solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}
.navpop > .container > .close > img{
  height: 30px;
  width: 30px;
  cursor: pointer;
}
/* .navpop > .container > .close:hover > i{
  font-size: 1.4em;
} */


.navpop > .container > .widget-con{
  height: auto;
  /* max-height: calc( 100% - 40px ); */
  width: calc(100%);
  /* padding-left: 20px; */
  /* background-color: red; */
}

.navpop > .container > .widget-con > .widget{
  width: calc(100% - 40px);
  padding: 15px 20px;
  margin-right: 20px;
  position: relative;
  /* background-color: red; */
  cursor: pointer;
  margin-bottom: 1px;
  text-decoration: none;
  color: black;
}
.navpop > .container > .widget-con > .widget:hover{
  background-color: var(--BG_WHITE_COLOR);
}
.navpop > .container > .widget-con.super > .widget{
  font-weight: bold;
}
.navpop > .container > .widget-con> .widget > img{
  margin-right: 20px;
  height: 30px;
  width: 30px;
}
.navpop > .container > .widget-con > .widget > span{
  font-family: avant-garde-md;
  cursor: pointer;
}
.navpop > .container > .widget-con.super > .widget > span{
  letter-spacing: 0.1em;
}
.navpop > .container > .widget-con> .widget > hr{
  height: 0.001px;
  width: calc(100% - 30px);
  position: absolute;
  bottom: -10px;
  left: 20px;
  cursor: pointer;
}


#svg_logo{
  margin-left: 10px;
  width: 120px;
  cursor: pointer;
}

@keyframes float1 {
  0% {
    transform: translatey(0px);
  }
  3% {
    transform: translatey(-20px);
  }
  9% {
    transform: translatey(20px);
  }
  12% {
    transform: translatey(0px);
  }
}


@media (max-width: 768px) { /* mobile */
  .navpop > .container{
    height: 100vh;
    max-height: 100vh;
    width: 100vw;
    
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    overflow-y: auto;
  }

}