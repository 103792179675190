.dashboard-container {
    max-width: var(--MAX_WIDTH);
    margin: 30px auto;
    min-height: 350px;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.dashboard-container-header{
    height: 60px;
    width: 100%;
    background-color: var(--MAIN_COLOR);
    border-radius: 10px;
}
.dashboard-container-header > img{
    cursor: pointer;
    padding: 0px 10px;
}
.dashboard-container-header > .title-con{
    min-width: 70%;
    height: 100%;
    padding: 0px 20px;
    cursor: pointer;
    position: relative;
}
.dashboard-container-header .month{
    font-size: 1.2em;
    color: white;
    cursor: pointer;
    padding: 5px;
}
.dashboard-container-header .month > p{
    font-size: 0.6em;
    margin-left: 10px;
}
.dashboard-container-header .week{
    font-size: 1.2em;
    color: white;
    cursor: pointer;
    padding: 5px 50px;
}
.dashboard-container-header > .title-con > .frag > p{
    color: green;
    font-size: 3em;
    cursor: default;
}
.dashboard-container-header > .title-con > .frag > p.return{
    color: red;
    cursor: help;
}
.dashboard-container-header > .title-con > .frag > img{
    height: 30px;
    width: 30px;
    margin-left: 20px;
    cursor: pointer;
}
.dashboard-container-header > .title-con > img{
    height: 30px;
    width: 30px;
    margin-left: 20px;
    cursor: pointer;
}

.dashboard-container-header > .title-con > .popup{
    width: calc(100% - 40px);
    height: auto;
    padding: 20px;
    position: absolute;
    top: 100%;
    left: 0px;
    background-color: var(--BG_WHITE_COLOR);
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    /* flex-col-center-start */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 100;
}
.dashboard-container-header > .title-con > .popup.show{
    display: flex;
}
.dashboard-container-header > .title-con > .popup > input[type="month"]{
    width: 90%;
    border: none;
    padding: 10px 20px;
    font-size: 1.2em;
    font-family: Poppins, sans-serif;
}
.dashboard-container-header > .title-con > .popup > input[type="month"]:active,
.dashboard-container-header > .title-con > .popup > input[type="month"]:focus{
    border-color: var(--MAIN_COLOR);
    border: thin solid black;

}
.dashboard-container-header > .title-con > .popup > button{
    width: 90%;
    padding: 10px 20px;
    margin-top: 20px;
}

.dashboard-container-content{
    /* background: red; */
    width: 100%;
    height: auto;
    margin-top: 10px;
}
.dashboard-container-content > .widget{
    height: auto;
    width: calc( (100% / 7) - 5px );
    /* background: red; */
}

.dashboard-container-content > .widget > .header{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    color: black;
    margin-bottom: 10px;
}
.dashboard-container-content > .widget > .header > p{
    margin-left: 5px;
}
.dashboard-container-content > .widget > .container{
    width: 100%;
    height: auto;
    /* background: red; */
    border: 1px solid pink;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    margin-bottom: 10px;
}
.dashboard-container-content > .widget > .container.closed{
    border-radius: 10px;
}

.dashboard-container-content > .widget > .container > .title{
    width: 100%;
    padding: 5px 0px;
    background-color: pink;
    text-align: center;
    color: white;
    cursor: pointer;
}
.dashboard-container-content > .widget > .container > .title > .numOfTr{
    font-size: 0.9em;
}
.dashboard-container-content > .widget  > .container > .title > img{
    height: 20%;
    width: 20%;
    cursor: pointer;
}
.dashboard-container-content > .widget > .container > .trans{
    width: calc(100% - 10px);
    height: 100%;
    /* background-color: red; */
}
.dashboard-container-content > .widget > .container > .trans > .widget{
    width: 100%;
    margin-bottom: 10px;
    border-bottom: thin dashed rgba(0, 0, 0, 0.2);
    font-family: 'Architects Daughter', cursive;
}
.dashboard-container-content > .widget > .container > .trans > .widget:last-child{
    border-bottom: none;
}
.dashboard-container-content > .widget > .container > .trans > .widget > .amount{
    font-size: 0.9em;
    width: 100%;
}
.dashboard-container-content > .widget > .container > .trans > .widget > .title:first-letter {
    text-transform: uppercase;
}
.dashboard-container-content > .widget > .container > .trans > .widget > .amount > img{
    height: 15px;
    width: 15px;
    padding: 5px;
    cursor: pointer;
}
.dashboard-container-content > .widget > .container > .trans > .widget > .amount > img:hover{
    transform: rotate(25deg);
}


.dashboard-container-content > .widget > .container.essential{
    border: 1px solid var(--ORANGE_PALETTE);
}
.dashboard-container-content > .widget > .container.essential  > .title{
    background-color: var(--ORANGE_PALETTE);
}

.dashboard-container-content > .widget > .container.optional{
    border: 1px solid var(--GREEN_PALETTE);
}
.dashboard-container-content > .widget > .container.optional  > .title{
    background-color: var(--GREEN_PALETTE);
}

.dashboard-container-content > .widget > .container.leisure{
    border: 1px solid var(--PINK_PALETTE);
}
.dashboard-container-content > .widget > .container.leisure  > .title{
    background-color: var(--PINK_PALETTE);
}

.dashboard-container-content > .widget > .container.extras{
    border: 1px solid var(--BLUE_PALETTE);
}
.dashboard-container-content > .widget > .container.extras  > .title{
    background-color: var(--BLUE_PALETTE);
}

.dashboard-container-content > .widget > .container.total{
    border: 1px solid grey;
}
.dashboard-container-content > .widget > .container.total  > .title{
    background-color: grey;
}




.dashboard-container-content > .widget.disabled{
    cursor: not-allowed;
}
.dashboard-container-content > .widget.disabled  > .container{
    background-color: rgb(226, 226, 226);
    border: 1px solid var(--BG_WHITE_COLOR);
    cursor: not-allowed;
}
.dashboard-container-content > .widget.disabled  > .container > .title{
    background-color: var(--BG_WHITE_COLOR);
    color: grey;
    cursor: not-allowed;
}





.dashboard-container-content > .weekly-summary-con{
    width: calc( (100% / 4) - 5px );
    height: 100%;
}
.dashboard-container-content > .weekly-summary-con > .title{
    width: calc(100% - 10px);
    min-height: 50px;
    padding: 5px;
    text-align: center;
    color: white;
}
.dashboard-container-content > .weekly-summary-con > .content{
    min-height: 100px;
    width: calc(100% - 10px);
}
.dashboard-container-content > .weekly-summary-con > .content > .widget{
    width: calc(100%);
    border-bottom: thin dashed rgba(0, 0, 0, 0.2);
    margin-top: 5px;
}
.dashboard-container-content > .weekly-summary-con > .content > .widget:last-child{
    border-bottom: none;
    font-weight: bold;
    border-top: thin solid black;
}
.dashboard-container-content > .weekly-summary-con > .content > .widget:nth-last-child(2){
    border-bottom: none;
}
.dashboard-container-content > .weekly-summary-con > .content > .widget span{
    font-size: 0.8em;
}

.dashboard-container-content > .weekly-summary-con > .content > .widget.empty > span{
    color: grey;
}


.dashboard-container-content > .weekly-total{
    width: calc(100% - 40px);
    height: auto;
    padding: 10px 20px;
    background-color: var(--MAIN_COLOR);
    color: white;
    margin-top: 20px;
}
.dashboard-container-content > .weekly-total > div > span:nth-child(2){
    font-size: 1.2em;
    border: 3px solid white;
    padding: 3px 10px;
    border-radius: 10px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}




.dashboard-container-content > .weekly-summary-con.essential{
    border: 1px solid var(--ORANGE_PALETTE);
}
.dashboard-container-content > .weekly-summary-con.essential  > .title{
    background-color: var(--ORANGE_PALETTE);
}

.dashboard-container-content > .weekly-summary-con.optional{
    border: 1px solid var(--GREEN_PALETTE);
}
.dashboard-container-content > .weekly-summary-con.optional  > .title{
    background-color: var(--GREEN_PALETTE);
}

.dashboard-container-content > .weekly-summary-con.leisure{
    border: 1px solid var(--PINK_PALETTE);
}
.dashboard-container-content > .weekly-summary-con.leisure  > .title{
    background-color: var(--PINK_PALETTE);
}

.dashboard-container-content > .weekly-summary-con.extras{
    border: 1px solid var(--BLUE_PALETTE);
}
.dashboard-container-content > .weekly-summary-con.extras  > .title{
    background-color: var(--BLUE_PALETTE);
}



.dashboard-container-content > .monthly-summary-nav > span{
    background-color: grey;
    color: rgba(252, 252, 252, 0.5);
    padding: 5px 10px;
    margin: 2px 0px;
    cursor: pointer;
}
.dashboard-container-content > .monthly-summary-nav > div{
    width: 1px;
    height: 100%;
}
.dashboard-container-content > .monthly-summary-nav > span.active{
    background-color: var(--BTN_COLOR);
    border: 2px solid var(--BTN_COLOR);
    color: white;
}
.dashboard-container-content > .monthly-summary-content{
    margin-top: 10px;
    width: 100%;
    height: auto;
}

.dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con{
    width: 100%;
    height: auto;
}
.dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .left-con{
    width: calc(65% - 10px);
    height: auto;
}
.dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .right-con{
    width: 35%;
    height: auto;
}


.dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .left-con > .title{
    font-size: 1.2em;
    background-color: var(--MAIN_COLOR);
    color: white;
    width: 100%;
    text-align: center;
}
.dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .left-con > .list-con{
    width: 100%;
    height: auto;
    
}
.dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .left-con > .list-con > .widget{
    width: calc(100% - 20px);
    padding: 5px 10px;
    background-color: var(--BG_WHITE_COLOR);
    margin-top: 10px;
    font-family: 'Architects Daughter', cursive;
}
.dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .left-con > .list-con > .widget.total{
    background-color: transparent;
    font-family: Poppins, sans-serif;
}
.dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .left-con > .list-con > .widget > span > img{
    height: 20px;
    width: 20px;
    cursor: pointer;
}
.dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .left-con > .list-con > .widget > span > img:hover,
.dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .left-con > .list-con > .widget > span > img:active{
    transform: rotate(25deg);
}

.dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .right-con > form{
    width: calc(100% - 20px);
    padding: 10px;
    background-color: var(--MAIN_COLOR);
    color: white;
    border-radius: 15px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}
.dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .right-con > form button{
    box-shadow: none;
}

.dashboard-container-content > .monthly-summary-content > .title{
    font-size: 1.4em;
    width: 70%;
    background-color: var(--MAIN_COLOR);
    color: white;
}
.dashboard-container-content > .monthly-summary-content > .title button{
    padding: 5px 10px;
    margin-left: 20px;
}
.dashboard-container-content > .monthly-summary-content > .goal-field{
    width: 70%;
}
.dashboard-container-content > .monthly-summary-content > .goal-field textarea{
    width: calc(100% - 20px);
    min-height: 50px;
    resize: none;
    font-family: Poppins, sans-serif;
    padding: 10px;
    font-size: 1.1em;
}
.dashboard-container-content > .monthly-summary-content > .goal-field-amount{
    width: 70%;
}
.dashboard-container-content > .monthly-summary-content > .goal-field-amount input,
.dashboard-container-content > .monthly-summary-content > .goal-field-amount span.total{
    font-size: 3em;
    width: 40%;
    padding: 10px 20px;
    text-align: center;
    padding-right: 10px;
    border: none;
    background-color: var(--MAIN_COLOR);
    color: white;
    border-radius: 15px;
}
.dashboard-container-content > .monthly-summary-content > .goal-field-amount input::-webkit-outer-spin-button,
.dashboard-container-content > .monthly-summary-content > .goal-field-amount input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.dashboard-container-content > .monthly-summary-content > .goal-field-amount input[type=number] {
  -moz-appearance: textfield;
}

.dashboard-container-content > .monthly-summary-content > .goal-field-summary{
    width: 70%;
}
.dashboard-container-content > .monthly-summary-content > .goal-field-summary > .widget-con{
    width: 100%;
}
.dashboard-container-content > .monthly-summary-content > .goal-field-summary > .widget-con > .widget{
    background-color: var(--MAIN_COLOR);
    color: white;
    width: calc(30% - 20px);
    padding: 10px;
    text-align: center;
    border-radius: 15px;
}
.dashboard-container-content > .monthly-summary-content > .goal-field-summary > .widget-con > .widget > .title{
    font-size: 0.95em;
    width: 100%;
}
.dashboard-container-content > .monthly-summary-content > .goal-field-summary > .widget-con > .widget > .amount{
    font-size: 3em;
}


.summary-chart-grid4{
    display: grid;
    grid-template-columns: 1fr 1fr;
}




.monthly-summary-significant-expenses-con{
    width: calc(450px - 20px);
    height: auto;
    /* background-color: var(--BG_WHITE_COLOR); */
    padding: 10px 10px 20px 10px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    margin-bottom: 20px;
}
.monthly-summary-significant-expenses-con > .title{
    width: calc(100% - 20px);
    padding: 3px 10px;
    font-size: 1.2em;
    background-color: var(--MAIN_COLOR);
    color: white;
    border-radius: 10px 10px 0px 0px;
}
.monthly-summary-significant-expenses-con > .widget{
    width: calc(100% - 20px);
    margin-top: 10px;
    border-bottom: thin dashed rgba(0, 0, 0, 0.2);
}
.monthly-summary-significant-expenses-con > .widget.super{
    font-weight: bold;
    border-bottom: none;
}
.monthly-summary-significant-expenses-con > .widget:first-child{
    border-bottom: none;
}
.monthly-summary-significant-expenses-con > .widget:last-child{
    border-bottom: none;
    border-top: thin solid rgba(0, 0, 0, 0.2);
}
.monthly-summary-significant-expenses-con > .widget:nth-last-child(2){
    border-bottom: none;
}


.monthly-summary-significant-expenses-con > .footer{
    width: 100%;
    height: auto;
    margin-top: 20px;
}

.monthly-summary-significant-expenses-con > .footer > .footwidget{
    padding: 5px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 0px 5px;
}
.monthly-summary-significant-expenses-con > .footer > .footwidget.active{
    background-color: var(--MAIN_COLOR);
    box-shadow: 0px 0px 3px 0px black;
}
.monthly-summary-significant-expenses-con > .footer > img{
    cursor: pointer;
}




















/* ///////////////////////// MOBILE */











@media (max-width: 768px) { /* mobile */
    :root{
        --HTITLE_SIZE: 3vw;
        --HSTITLE_SIZE: 4.4vw;
        --MTITLE_SIZE: 1.6em;
        --MSTITLE_SIZE: 1.4em;
        --TITLE_SIZE: 1.3em;
        --TEXT_SIZE: 1em;
        --STEXT_SIZE: 0.9em;
    }


    .dashboard-container {
        max-width: 100vw;
        margin: 0px 0px 30px 0px;
        min-height: 350px;
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    
    .dashboard-container-header{
        width: calc(100% - 20px);
        margin-top: 10px;
    }
    .dashboard-container-header > img{
        cursor: pointer;
        padding: 0px 0px;
        /* background-color: red; */
        height: 30px;
        width: 30px;
    }
    .dashboard-container-header > .title-con{
        min-width: calc(100% - 70px);
        max-width: calc(100% - 70px);
        height: 100%;
        /* background-color: red; */
        padding: 0px 0px;
        cursor: pointer;
        position: relative;
    }
    .dashboard-container-header > .title-con > img{
        height: 30px;
        width: 30px;
        margin-left: 0px;
        cursor: pointer;
    }

    .dashboard-container-header > .title-con > .frag > img{
        height: 30px;
        width: 30px;
        margin-left: 20px;
        cursor: pointer;
    }


    .dashboard-container-content > .weekly-total{
        width: calc(100% - 40px);
        height: auto;
        padding: 10px;
        background-color: var(--MAIN_COLOR);
        color: white;
        margin-top: 20px;
    }
    .dashboard-container-content > .weekly-total > div > span:nth-child(2){
        font-size: 1.2em;
        border: 3px solid white;
        padding: 3px 10px;
        border-radius: 10px;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    }



    .dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con{
        position: relative;
    }
    .dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .left-con{
        width: calc(100% - 20px);
        height: auto;
    }
    .dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .right-con{
        width: 35%;
        height: auto;
    }
    .dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .left-con > .title{
        font-size: 1.2em;
        background-color: var(--MAIN_COLOR);
        color: white;
        width: calc(100% - 20px);
        text-align: center;
        padding: 5px 10px;
    }
    .dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .left-con > .title > img{
        height: 30px;
        width: 30px;
    }

    .dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .right-con{
        width: calc(100% - 20px);
        height: auto;
        position: absolute;
        left: 10px;
        top: 0px;
        z-index: 100;
    }
    .dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .right-con  > form > .title{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .dashboard-container-content > .monthly-summary-content > .monthly-summary-list-con > .right-con  > form > .title > img{
        height: 30px;
        width: 30px;
    }
    

    .dashboard-container-content > .monthly-summary-content > .title{
        font-size: 1.4em;
        width: calc(100% - 40px);
        padding: 5px 10px;
        background-color: var(--MAIN_COLOR);
        color: white;
        position: relative;
    }
    .dashboard-container-content > .monthly-summary-content > .title button{
        padding: 5px 10px;
        /* margin-left: 20px; */
        /* position: absolute; */
    }
    .dashboard-container-content > .monthly-summary-content > .goal-field{
        width: calc(100% - 20px);
    }
    .dashboard-container-content > .monthly-summary-content > .goal-field-amount{
        width: calc(100% - 20px);
    }
    .dashboard-container-content > .monthly-summary-content > .goal-field-summary{
        width: calc(100% - 20px);
    }
    .dashboard-container-content > .monthly-summary-content > .goal-field-summary > .widget-con > .widget{
        height: 100%;
        min-height: 120px;
    }
    .dashboard-container-content > .monthly-summary-content > .goal-field-summary > .widget-con > .widget > .amount{
        font-size: 1.4em;
        font-weight: bold;
    }
    .dashboard-container-content > .monthly-summary-content > .goal-field-amount span.total{
        font-size: 3em;
        width: calc( 100% - 20px );
        padding: 10px 20px;
        text-align: center;
        padding-right: 10px;
        border: none;
        background-color: var(--MAIN_COLOR);
        color: white;
        border-radius: 15px;
    }
    .summary-chart-grid4{
        display: flex;
        grid-template-columns: unset;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }


    .monthly-summary-significant-expenses-con{
        width: calc(100vw - 40px);
        height: auto;
        /* background-color: var(--BG_WHITE_COLOR); */
        padding: 10px 10px 20px 10px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
        border-radius: 15px;
    }
    .monthly-summary-significant-expenses-con > .footer > .footwidget{
        padding: 3px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.5);
        margin: 0px 3px;
    }

}
@media (min-width: 767px) and (max-width: 1280px) {  /* regular */
    :root{
        --HTITLE_SIZE: 2.6vw;
        --HSTITLE_SIZE: 1.7vw;
        
        --MTITLE_SIZE: 2em;
        --MSTITLE_SIZE: 1.7em;
        --TITLE_SIZE: 1.5em;
        --TEXT_SIZE: 1.3em;
        --STEXT_SIZE: 1em;
    }
    
    body{
        /* background-color: orange; */
    }

    
    
}
@media (min-width: 1281px) and (max-width: 2399px) { /* wide */
    :root{
        --HTITLE_SIZE: 3vw;
        --HSTITLE_SIZE: 1.7vw;
        --MTITLE_SIZE: 2.2em;
        --MSTITLE_SIZE: 2em;
        --TITLE_SIZE: 1.7em;
        --TEXT_SIZE: 1.5em;
        --STEXT_SIZE: 1.3em;
    }
    body{
        /* background-color: yellow; */
    }
    
   
}
@media (min-width: 2400px) {  /* ultrawide */
    :root{
        --HTITLE_SIZE: 3em;
        --HSTITLE_SIZE: 1.8em;
        --MTITLE_SIZE: 2.5em;
        --MSTITLE_SIZE: 2.3em;
        --TITLE_SIZE: 2em;
        --TEXT_SIZE: 1.8em;
        --STEXT_SIZE: 1.5em;
    }
    body{
        /* background-color: green; */
    }



}